<template>
  <b-card title="Lista de promoçoes">
    <b-button 
      v-if="addInfo == 'userPage'"
      variant="gradient-primary mb-1" 
      @click="openRefModal"
    >
      Alterar grupos
    </b-button>
    <b-table
      v-if="searchedPromos && all_wallet_types"
      :items="searchedPromos"
      :fields="fields"
      striped
      responsive
      class="data-table"
      :key="k"
    >
      <template #cell(actions)="data">
        <div v-if="(contextActions == 'managePromotionInsideGroup')">
          <router-link :to="{ path: '/promotion-single/'+ data.item.id}">
            <feather-icon
              icon="EyeIcon"
              class="ml-2 b-fon text-primary pointer"
            />
          </router-link>
          <feather-icon
            icon="MinusIcon"
            class="ml-2 b-fon text-danger pointer"
            @click="removePromotionGroup(data.item)"
          />
        </div>
        <div v-if="(contextActions == 'managePromotionGroups')">
          <feather-icon
            v-if="!checkAlready(data.item.id)"
            icon="PlusIcon"
            class="ml-2 b-fon text-primary pointer"
            @click="addPromotionGroup(data.item)"
          />
          <feather-icon
            v-else
            icon="MinusIcon"
            class="ml-2 b-fon text-danger pointer"
            @click="removePromotionGroup(data.item)"
          />
        </div>
        <div v-if="contextActions == 'managePromotion'">
          <router-link :to="{ path: '/promotion-single/'+ data.item.id}">
            <feather-icon
              icon="EyeIcon"
              class="ml-2 b-fon text-primary pointer"
            />
          </router-link>
        </div>
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value]}}
        </b-badge>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BBadge,BCardText, BLink
} from 'bootstrap-vue'
import gql from 'graphql-tag'

import {_} from 'vue-underscore';

export default {
  emits: ['addPromotionGroup', 'remove-promotion-group'],
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BCardText,
    BBadge,
    BLink,
    underscore: _,
    gql
  },
  props:['searchedPromos', 'contextActions', 'addInfo', 'customFields'],
  data() {
    return {
      fields: ['id', 'name','title',{"key":'deposit_related', "label":"Calc. sobre Dep.", "formatter": "boolName"},'value',{"key":'wallet_type_id', "label":"Carteira", "formatter": "walletName"}],
      k:0,
      localInfo: [],
    }
  },
  apollo:{
    all_wallet_types: {
      query: gql`query{
        all_wallet_types{
          id,
          code,
        }
      }`,
      client: 'walletClient'
    }
  },
  watch:{
    addInfo: function(){
      this.localInfo = this.addInfo;
    },
  },
  methods:{
    checkAlready(id){
      if(this.localInfo != null && this.localInfo.length >0){
        return this.localInfo.filter(a => a.id == id).length
      }
    },
    addPromotionGroup(item){
      if(this.localInfo == null){
        this.$emit('addPromotionGroup', {'g_id': item.id, 'g_arr' : [item]})
      }else{
        this.localInfo = this.localInfo.concat(item)
        this.$emit('addPromotionGroup', {'g_id': item.id, 'g_arr' : this.localInfo})
      }
      this.k++;
    },
    removePromotionGroup(item){
      this.localInfo = this.localInfo.filter(r => r.id != item.id)
      this.$emit('remove-promotion-group',{'g_id': item.id, 'g_arr' : this.localInfo})
      this.k++;
    },
    markItem(e,l,b){
      if(e.id != this.idToMark){
        this.localInfo = this.localInfo.concat(e)
        e.selected = this.marker;
        this.k++;
      }
    },
    openRefModal(){
      this.$emit('showGroupModal',true)
    },
    walletName(e){
      return this.all_wallet_types.filter(w => w.id == e)[0].code
    },
    boolName(e){
      let r = ['Não','Sim']
      return r[e]
    }


  },
  created(){
    if(this.contextActions != null){
      this.fields.push('actions')
    }
    this.localInfo = this.addInfo;
    // console.log(this.localInfo)
  }
}
</script>

<style>
  .floater{
    /* position: absolute; */
    z-index: 99;
  }
  .data-table tbody{
    padding-bottom: 30px;
  }
  .b-fon{
    width: 25px;
    height: 25px;
  }
</style>